import { createRouter, createWebHashHistory } from 'vue-router'

// CHOOSE
import PageStart from '@/views/choose/PageStart.vue'
import PageCheck from '@/views/choose/PageCheck.vue'
import PageChooseNotEnabled from '@/views/choose/PageChooseNotEnabled.vue'

// VERIFY
import PageStartVerify from '@/views/verify/PageStartVerify.vue'
import PageCheckVerify from '@/views/verify/PageCheckVerify.vue'
import PageVerifyNotEnabled from '@/views/verify/PageVerifyNotEnabled.vue'

// СКВОЗНЫЕ
import PageEvaluating from '@/views/PageEvaluating.vue'
import PageEvaluated from '@/views/PageEvaluated.vue'

// ТЕХНИЧЕСКИЕ
import Page401 from '@/views/Page401.vue'
import Page404 from '@/views/Page404.vue'

const routes = [
  // ========= ЭТАП CHOOSE

  {
    path: "/start/:lang/:token",
    component: PageStart,
    name: "start",
  },
  {
    path: '/evaluating/:lang/:token',
    component: PageEvaluating,
    name: 'evaluating',
    meta: {
      mode: 'Evaluating',
    },
  },
  {
    path: '/evaluated/:lang/:token',
    component: PageEvaluated,
    name: 'evaluated',
    meta: {
      mode: 'Evaluated',
    },
  },
  {
    path: '/check/:lang/:token',
    component: PageCheck,
    name: 'check',
  },
  {
    path: '/choose-not-enabled/:lang/:token',
    component: PageChooseNotEnabled,
    name: 'choose-not-enabled'
  },

  // ========= ЭТАП VERIFY

  {
    path: '/start-verify/:lang/:token',
    component: PageStartVerify,
    name: 'startVerify',
    meta: {
      verify: true,
    },
  },
  {
    path: '/evaluating-verify/:lang/:respondentId/:token',
    component: PageEvaluating,
    name: 'evaluatingVerify',
    meta: {
      mode: 'Evaluating',
      verify: true,
    },
  },
  {
    path: '/evaluated-verify/:lang/:respondentId/:token',
    component: PageEvaluated,
    name: 'evaluatedVerify',
    meta: {
      mode: 'Evaluated',
      verify: true,
    },
  },
  {
    path: '/verify/:lang/:respondentId?/:token',
    component: PageCheckVerify,
    name: 'verify',
    meta: {
      verify: true,
    },
  },
  {
    path: '/verify-not-enabled/:lang/:token',
    component: PageVerifyNotEnabled,
    name: 'verify-not-enabled',
    meta: {
      verify: true,
    },
  },

  // ========= ТЕХНИЧЕСКИЕ

  {
    path: '/:lang/401',
    component: Page401,
    name: '401',
  },
  {
    path: '/:lang/*',
    component: Page404,
    name: '404',
  },
]

const router = createRouter({
  history: createWebHashHistory('/start'),
  routes: routes,
})

export default router
