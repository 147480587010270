<template>
  <span 
    class="inline-flex items-center rounded-3xl leading-[34px] px-[18px] py-[4px] md:leading-[24px] md:px-[10px]"
    :style="{
      'background-color': props.bgColor,
      'color': props.color,
    }"
  >
    {{ count }}
    <Icon name="user" class="ml-[6px] md:w-[12px] md:ml-[3px]" />
  </span>
</template>  
  
<script setup>
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  count: '',
  bgColor: '#313131',
  color: '#ffffff',
})
</script>