<template>
  <div v-if="isBaseModalOpen" class="fixed inset-0 z-[100] bg-[black] bg-opacity-60 flex justify-center items-center">
    <div class="relative bg-[white] w-auto max-w-[400px] rounded-md p-[20px] mx-[20px] text-gray">
      <button class="absolute top-[10px] right-[10px]" @click="closeModal">
        <span class="cross cursor-pointer"></span>
      </button>

      <div class="mt-[12px] mb-[20px]">
        <slot name="body"></slot>
      </div>
      <div class="text-center">
        <button class="!inline-block button border border-[#c6c6c6]" @click="closeModal">ОК</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useMutations, useState } from '@/store/useStore'

const {
  isBaseModalOpen
} = useState()

const {
  setBaseModalVisible
} = useMutations()

const closeModal = () => {
  setBaseModalVisible(false)
}
</script>