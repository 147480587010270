import { watch } from 'vue'

import { useGetters } from '@/store/useStore'
import { useRoute, useRouter } from 'vue-router'

export function useFindCoupleRole(role, relations) {
  const targetRelation = relations.find(relation => relation.roleEvaluatedId === role.roleId || relation.roleEvaluatingId === role.roleId)

  if (targetRelation.roleEvaluatedId === targetRelation.roleEvaluatingId) return targetRelation.roleEvaluatedId
  else if (targetRelation.roleEvaluatedId !== role.roleId) return targetRelation.roleEvaluatedId
  else return targetRelation.roleEvaluatingId
}

export const useVerify = () => {
  const route = useRoute()
  return route.meta?.verify ?? false
}

export const usePhasesController = () => {
  const {
    getAttributesReady,
    getPhaseChooseEnabled,
    getPhaseVerifyEnabled,
    getSurveyText,
    getSteps
  } = useGetters()

  const route = useRoute()
  const router = useRouter()

  watch(() => getAttributesReady.value, (bool) => {
    if (bool) {
      if (route.meta?.verify) {
        document.title = getSurveyText.value('textAppTitleVerify')

        if (getPhaseVerifyEnabled.value && route.name === 'verify-not-enabled')
          router.push({ name: 'startVerify' })

        if (!getPhaseVerifyEnabled.value)
          router.push({ name: 'verify-not-enabled' })
      } else {
        document.title = getSurveyText.value('textAppTitle')
        
        if(getPhaseChooseEnabled.value && route.name !== 'choose-not-enabled' && (!getSteps.value.evaluating && !getSteps.value.evaluated))
          router.push({name: 'check'})

        if (getPhaseChooseEnabled.value && route.name === 'choose-not-enabled') 
          router.push({ name: 'start' })

        if (!getPhaseChooseEnabled.value)
          router.push({ name: 'choose-not-enabled' })
      }
    }
  })
}

export const useStringFirstSpaceTransposition = (str, isUseSpan = false) => {
  let spacedString = str
  let spanStart = ''
  let spanEnd = ''

  if (isUseSpan) {
    spanStart = '<span>'
    spanEnd = '</span>'
  }

  const spaceIndex = str.indexOf(' ')
  if (spaceIndex > -1) spacedString = `${spacedString.slice(0, spaceIndex)}<br />${spanStart}${spacedString.slice(spaceIndex, spacedString.length)}${spanEnd}`

  return spacedString
}

export const useScrollStepsController = () => {
  const step1 = document.querySelector('.step-1')
  const step2 = document.querySelector('.step-2')

  const checkPositions = () => {
    const step1Height = step1.offsetHeight
    const step2ClientY = step2.getBoundingClientRect().top

    if (step2ClientY <= step1Height) {
      step2.classList.add('step-active')
      step2.style.top = `${step2ClientY}px`
    } else {
      step2.classList.remove('step-active')
      step2.style.top = 'auto'
    }
  }

  const triggerClick = (stepNumber) => {
    const step1Height = step1.offsetHeight
    const step2Height = step2.offsetHeight

    const stepTriggerTop = document.querySelector(`.step-${stepNumber}-trigger`).getBoundingClientRect().top
    const heightGap = stepNumber === 1 ? step1Height : (step1Height + step2Height)

    const top = stepTriggerTop - heightGap + window.scrollY

    window.scrollTo({ 
      top, 
      behavior: 'smooth' 
    })
  }

  step1.addEventListener('click', ($event) => {
    triggerClick(1)
  })

  step2.addEventListener('click', ($event) => {
    if (step2.classList.contains('step-active') && $event.target.classList.contains('pi')) {
      triggerClick(1)
    } else {
      triggerClick(2)
    }
  })

  window.addEventListener('scroll', () => {
    checkPositions()
  })
}

export const useRespondentsSortedByRoles = (respondents, mode) => {
  const {
    getAllRoles,
  } = useGetters()

  const roles = {}
  
  respondents.forEach(respondent => {
    roles[respondent[`${mode}RoleId`]] = null
  })

  const rolesList = Object.keys(roles)
    .map(targetRoleId => {
      return {
        ...getAllRoles.value.find(({ roleId }) => roleId === targetRoleId) 
        ?? 
        { 
          label: '-.-', 
          roleId: '12345', 
          localizedAttributes: {
            order: Date.now(),
          },
        },
        respondents: respondents.filter(respondent => respondent[`${mode}RoleId`] === targetRoleId)
      }
    })
    .sort((a, b) => a.localizedAttributes.order - b.localizedAttributes.order)

  return rolesList
}