<template>
  <div class="grid grid-rows-[max-content_auto] h-full md:pt-[20px]">
    <StepSticky
      :title="getSurveyText(`textStep2${props.mode}`)"
      class="md:!hidden"
    />

    <div class="relative">
      <div class="absolute inset-0 md:relative">
        <div
          v-if="getRespondents.length && getAttributesReady"
          class="pretty-scrollbar wide overflow-x-auto mb-[30px] max-h-[calc(100%-77px)] md:max-h-auto"
        >
          <div
            v-for="respondent in getRespondents"
            :class="{ error }"
            :key="respondent.respondentId"
          >
            <CheckedRespondent
              :all-respondents="getRespondents"
              :respondent="getRespondentById(respondent.respondentId)"
              :respondent-roles="respondent"
              :respondent-id="respondent.respondentId"
              :mode="mode"
              :current-respondent-id="currentRespondentId"
              :validate="!!respondent[modeString]"
              @change="change"
            />
          </div>
        </div>

        <div class="flex justify-center md:pb-[40px]">
          <button
            @click="validate"
            class="button"
            :class="{ error }"
            :style="{
              'background-color': getColor(props.mode),
              color: getColorContrast(props.mode),
            }"
          >
            <Icon name="check" class="mr-[8px]" />
            Сохранить
          </button>
        </div>
      </div>
    </div>
  </div>
  <BaseModal>
    <template v-slot:body>
      <div class="grid gap-[10px]">
        <div v-for="(value, index) in errorsMessage" :key="index">
          <span class="font-medium">{{
            getSurveyText("textLimitPanel").replace("{число}", value.count)
          }}</span>
          <div>
            <div v-for="role in value.value" :key="role">
              {{ getSurveyText("textLimitPanelErrorItem").replace('{роль}', role).replace('{число}', value.count) }}
            </div>
          </div>
          <hr class="mt-2" v-if="index !== errorsMessage.length - 1" />
        </div>
      </div>
    </template>
  </BaseModal>

  <CustomBaseModal v-model="isShowWarningModal" @close="handleWarningClose">
    <template v-slot:body>
      <div v-html="getWarningText()">
      </div>
    </template>
  </CustomBaseModal>
</template>

<script setup>
import { ref, watch } from "vue";
import { useGetters, useMutations } from "@/store/useStore";
import { useRoute, useRouter } from "vue-router";

import CustomBaseModal from "@/components/UI/CustomBaseModal.vue";
import StepSticky from "@/components/StepSticky.vue";
import Icon from "@/components/UI/Icon.vue";
import CheckedRespondent from "@/components/CheckedRespondent.vue";
import BaseModal from "@/components/UI/BaseModal.vue";

const props = defineProps({
  mode: {
    type: String,
    required: true,
  },
  nextRoute: {
    type: String,
    required: true,
  },
  currentRespondentId: String,
});

const route = useRoute();
const router = useRouter();
const error = ref(false);
const errorsMessage = ref([]);
const isShowWarningModal = ref(false);



const getWarningText = () => {
  const min = getSurveySettingVerify.value('recommendMinRespondentsCount')
  const max = getSurveySettingVerify.value("recommendMaxRespondentsCount")
  return getSurveyText
    .value("recommendMinRespondentsText")
    .replace("{число}", min)
    .replace("{число}", max);
};

const handleWarningClose = () => {
  isShowWarningModal.value = false;
  if (props.nextRoute === "verify") {
    router.push({
      name: props.nextRoute,
      params: {
        respondentId: route.params.respondentId,
      },
    });
  } else {
    router.push({
      name: props.nextRoute,
    });
  }
};

const {
  getSurveyText,
  getSurveySettingVerify,
  getRespondentById,
  getAttributesReady,
  getColor,
  getColorContrast,
  getAllRoles,
  getMinSelectedEmployeesRoles,
} = useGetters();

const { setBaseModalVisible } = useMutations();

const getRespondents = useGetters()[`getRespondents${props.mode}`];
const modeString = props.mode.toLowerCase() + "RoleId";

const isAllSelected = () => {
  return !getRespondents.value.find(
    (element) => element[`${modeString}`] === null
  );
};

watch(
  () => getRespondents.value,
  () => {
    if (
      getRespondents.value.length ||
      getMinSelectedEmployeesRoles.value.length === 0
    ) {
      error.value = false;
    } else {
      error.value = true;
    }
  },
  { immediate: true, deep: true }
);

const isInRange = (num, min, max) => num >= min && num <= max

const minSelectedRespondentsValidate = () => {
  errorsMessage.value = [];

  const groupedErrors = new Map();
  let isRolesEnabled = false;

  getAllRoles.value.forEach((item) => {
    const minSelectedEmployeesNumber =
      item.localizedAttributes?.minSelectedEmployees;
    if (minSelectedEmployeesNumber) {
      isRolesEnabled = true
      const checkedRolesArr = getRespondents.value.filter(
        (respondent) => respondent[`${modeString}`] === item.roleId
      );

      if (checkedRolesArr.length < minSelectedEmployeesNumber) {
        if (groupedErrors.has(minSelectedEmployeesNumber)) {
          const value = groupedErrors.get(minSelectedEmployeesNumber);
          groupedErrors.set(minSelectedEmployeesNumber, [...value, item.label]);
        } else {
          groupedErrors.set(minSelectedEmployeesNumber, [item.label]);
        }
      }
    }
  });


  errorsMessage.value = Array.from(groupedErrors, ([count, value]) => ({
    count,
    value,
  }));

  const min = getSurveySettingVerify.value('recommendMinRespondentsCount')
  const max = getSurveySettingVerify.value("recommendMaxRespondentsCount")
  const isRangeDisabled = min === 0 && max === 0
  const selfEvalRoleId = getAllRoles.value.find(el => el.selfEvaluation === true)
  const hasSelfEsteemRole = getRespondents.value.find(r => r.evaluatingRoleId === selfEvalRoleId.roleId)
  if(getWarningText() !== '-.-' && getWarningText()?.length && !Number.isNaN(min) && !Number.isNaN(max)) {
    if (props.mode === "Evaluating" && !errorsMessage.value.length && (!(isInRange(getRespondents.value.length, min, max) || isRangeDisabled) || !hasSelfEsteemRole) && !isRolesEnabled) {
      error.value = false;
      isShowWarningModal.value = true;
      return false;
    }
  }

  if (errorsMessage.value.length) {
    setBaseModalVisible(true);
    return false;
  } else {
    error.value = false;
    return true;
  }
};

const change = (selectObject) => {
  getRespondents.value.forEach((resp) => {
    if (
      selectObject[resp.respondentId] ||
      selectObject[resp.respondentId] === null
    ) {
      resp[modeString] = selectObject[resp.respondentId];
    }
  });
  checked();
};

const checked = () => {
  setTimeout(() => {
    if (
      !getRespondents.value.find((element) => element[`${modeString}`] === null)
    ) {
      error.value = false;
    }
    if (
      getRespondents.value.length === 0 &&
      getMinSelectedEmployeesRoles.value.length !== 0
    )
      error.value = true;
  }, 0);
};

const validate = () => {
  if (isAllSelected() && minSelectedRespondentsValidate()) {
    if (props.nextRoute === "verify") {
      router.push({
        name: props.nextRoute,
        params: {
          respondentId: route.params.respondentId,
        },
      });
    } else {
      router.push({
        name: props.nextRoute,
      });
    }
  } else {
    error.value = true;
    setTimeout(() => {
      const errorElements = document.querySelectorAll(".error .error");
      if (errorElements.length) {
        errorElements[0].scrollIntoView({
          behavior: "smooth",
        });
      }
    }, 0);
  }
};
</script>

<style lang="scss" scoped>
button.error {
  pointer-events: none;
  opacity: 0.5;
}

::v-deep(.p-scrollpanel.custombar .p-scrollpanel-bar) {
  background-color: #ccc;
  opacity: 1;
  transition: background-color 0.3s;
  width: 5px;
  transform: translateX(10px);
}

::v-deep(.p-scrollpanel.custombar .p-scrollpanel-bar:hover) {
  background-color: #ccc;
}
.save {
  z-index: 1;
}
@media (min-width: 768px) {
  .save {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
  .save-md {
    display: none;
  }
}
@media (max-width: 768px) {
  .save-lg {
    display: none;
  }
}
</style>
