<template>
  <div
    v-if="isBaseModalOpen"
    class="fixed inset-0 z-[100] bg-[black] bg-opacity-60 flex justify-center items-center"
  >
    <div
      class="relative bg-[white] w-auto max-w-[400px] rounded-md p-[20px] mx-[20px] text-gray"
    >
      <button class="absolute top-[10px] right-[10px]" @click="emit('close')">
        <span class="cross cursor-pointer"></span>
      </button>

      <div class="mt-[12px] mb-[20px]">
        <slot name="body"></slot>
      </div>
      <div class="text-center">
        <button
          class="!inline-block button border border-[#c6c6c6]"
          @click="emit('close')"
        >
          ОК
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>

const isBaseModalOpen = defineModel();

const emit = defineEmits(['close'])
</script>
