import router from "@/router"
import HttpService from "@/services/HttpService"

export default {
  state: {
    verifyingParams: {
      respondentId: '',
      email: null,
      fullName: null,
      chooseCompleted: false,
      phases: {
        choose: false,
        evaluate: false,
        verify: false,
      },
      relations: [],
      respondentCanVerify: false,
      respondentCanBeEvaluated: false,
      respondentCanEvaluate: false,

      surveyAttributes: {},
      surveyLocalizedAttributes: {
        texts: {
          text401Title: 'Уважаемый сотрудник!',
          text401Text: 'Если этап оценки ещё не закончен, попросите у компетентных коллег прислать новую ссылку для продолжения.'
        },
      },
      attributesReady: false,
    },

    // ========

    childrenDepsAndResps: {},

    // ========

    evaluatedRespondents: [],
    evaluatingRespondents: [],

    // ========

    searchQuery: '',
    searchRespondents: [],
    searchLoading: false,

    // ========

    respondentsForVerification: [],
    selectedRespondentForVerification: {},

    // ========

    isBaseModalOpen: false,

    // ========

    evaluatingErrors: [],
    evaluatedErrors: [],
  },
  getters: {
    // Получение данных по респонденту, который выбирает
    getCurrentRespondent: ({ verifyingParams }) => {
      const { respondentId, email, fullName, photoUrl } = verifyingParams

      return {
        respondentId,
        email,
        fullName,
        photoUrl,
      }
    },

    // TODO: есть подозрения, что задублирована
    getPhaseVerify: (_, getters) => {
      return getters.getRespondentCanVerify && getters.getPhaseVerifyEnabled && router.currentRoute.value.meta?.verify
    },

    // Функция для получения любого текстового поля из атрибутов surveyInfo
    getSurveyText: (state, getters) => (param) => {
      if (state?.verifyingParams?.surveyLocalizedAttributes?.texts) {
        if (getters.getPhaseVerify) {
          return state?.verifyingParams?.surveyLocalizedAttributes?.texts[`${param}Verify`]
            ? state?.verifyingParams?.surveyLocalizedAttributes?.texts[`${param}Verify`]
            : state?.verifyingParams?.surveyLocalizedAttributes?.texts[param]
              ? state?.verifyingParams?.surveyLocalizedAttributes?.texts[param]
              : '-.-'
        }

        return state?.verifyingParams?.surveyLocalizedAttributes?.texts[param] ?? '-.-'
      }
      return '-.-'
    },

    // Функция получения любого нетекстового поля из атрибутов surveyInfo
    getSurveySetting: (state) => (param) => {
      if (state?.verifyingParams?.surveyAttributes) {
        return state?.verifyingParams?.surveyAttributes[param] ?? null
      }
      return null
    },

    getSurveySettingVerify: (state, getters) => (param) => {
      if (state?.verifyingParams?.surveyAttributes) {
        const key = `${param}${getters.getPhaseVerify ? 'Verify' : ''}`
        return state?.verifyingParams?.surveyAttributes[key] ?? null
      }
      return null
    },

    // Функция получения бренд цвета
    getColor: (state) => (mode = 'evaluating') => {
      if (mode.toLowerCase() === 'evaluating') {
        return state?.verifyingParams?.surveyAttributes?.mainColor
      }

      if (mode.toLowerCase() === 'evaluated') {
        return state?.verifyingParams?.surveyAttributes?.mainColor2 ?? state?.verifyingParams?.surveyAttributes?.mainColor
      }
    },

    getColorContrast: (state) => (mode = 'evaluating') => {
      if (mode.toLowerCase() === 'evaluating') {
        return state?.verifyingParams?.surveyAttributes?.mainColorContrast
      }

      if (mode.toLowerCase() === 'evaluated') {
        return state?.verifyingParams?.surveyAttributes?.mainColorContrast2 ?? state?.verifyingParams?.surveyAttributes?.mainColorContrast
      }
    },

    // загружены ли атрибуты в систему
    getAttributesReady: (state) => {
      return state.verifyingParams.attributesReady
    },

    // функция получения дочерних списков департаментов и респондентов по айди департамента
    getChildrenDepsAndResps: (state) => (departmentId) => {
      return state.childrenDepsAndResps[String(departmentId)]
    },

    // массив респондентов, которых надо будет оценить
    getRespondentsEvaluating: (state) => {
      return state.evaluatingRespondents.sort((a, b) => a.weight - b.weight)
    },

    // массив респондентов, которые будут оценивать
    getRespondentsEvaluated: (state) => {
      return state.evaluatedRespondents.sort((a, b) => a.weight - b.weight)
    },

    // объект департаментов для открытия дерева
    getDepartmentIdsEvaluating: (state) => {
      const departmentIds = {}
      state.evaluatingRespondents.forEach(respondent => {
        if (respondent?.departmentIds) {
          respondent.departmentIds.forEach(departmentId => departmentIds[departmentId] = true)
        }
      })

      return departmentIds
    },

    // объект департаментов для открытия дерева
    getDepartmentIdsEvaluated: (state) => {
      const departmentIds = {}
      state.evaluatedRespondents.forEach(respondent => {
        if (respondent?.departmentIds) {
          respondent.departmentIds.forEach(departmentId => departmentIds[departmentId] = true)
        }
      })

      return departmentIds
    },

    // объект всех подгруженных в систему респондентов
    getAllRespondents: (state) => {
      let allRespondents = {}

      Object.keys(state.childrenDepsAndResps).forEach(key => {
        allRespondents = { ...allRespondents, ...state.childrenDepsAndResps[key].respondents }
      })

      return allRespondents
    },

    // массив айди getters.getAllRespondents
    getRespondentById: (_, getters) => (respondentId) => {
      return getters.getAllRespondents[respondentId]
    },

    getAllRoles: (state) => {
      const allRoles = {}
      let isDefaultRole = false

      state.verifyingParams.relations.map(relation => {
        const indexes = [0, 1]

        indexes.forEach(index => {
          allRoles[relation.roles[index].roleId] = {
            ...relation.roles[index],
            selfEvaluation: relation.selfEvaluation,
          }

          const defaultRole = relation.roles[index].localizedAttributes?.defaultRole ?? null
          if (defaultRole) isDefaultRole = true
        })
      })

      const emptyRole = isDefaultRole
        ? []
        : [{ label: '?', roleId: null }]


      Object.keys(allRoles).forEach(roleKey => {
        const label = allRoles[roleKey]?.localizedAttributes?.newLabel
        if(label && label?.length) {
          allRoles[roleKey].label = label
        }
      })

      return [
        ...emptyRole,
        ...Object.keys(allRoles).map(role => ({
          ...allRoles[role]
        }))
      ]
    },

    getRoleById: (_, getters) => (roleId) => {
      return getters.getAllRoles.filter(role => role.roleId === roleId)
    },

    getMinSelectedEmployeesRoles: (_, getters) => {
      return getters.getAllRoles.filter(role => role.localizedAttributes?.minSelectedEmployees)
    },

    // массив связей в системе
    getRelations: (state) => {
      return state.verifyingParams.relations.sort((a, b) => a.weight - b.weight).map(relation => ({
        ...relation,
        roleEvaluatingLabel: relation.roles[0].label,
        roleEvaluatingId: relation.roles[0].roleId,
        roleEvaluatedLabel: relation.roles[1].label,
        roleEvaluatedId: relation.roles[1].roleId,
      }))
    },

    // окончен ли этап выбора для конкретного респондента
    getChooseCompleted: (state) => {
      return state.verifyingParams.chooseCompleted
    },

    // активен ли этап выбора для всех респондентов
    getPhaseChooseEnabled: (state, getters) => {
      if (!getters.getAttributesReady) return true
      return state.verifyingParams.phases.choose
    },

    // активен ли этап верификации для всех респондентов
    getPhaseVerifyEnabled: (state, getters) => {
      if (!getters.getAttributesReady) return true
      return state?.verifyingParams?.phases?.verify && state?.verifyingParams?.respondentCanVerify
    },

    // является ли респондент верификатором
    getRespondentCanVerify: (state) => {
      return state?.verifyingParams?.respondentCanVerify
    },

    // может ли респондент выбирать кто его оценит
    getRespondentCanBeEvaluated: (state) => {
      return state?.verifyingParams?.respondentCanBeEvaluated
    },

    // может ли респондент выбирать кого оценит он
    getRespondentCanEvaluate: (state) => {
      return state?.verifyingParams?.respondentCanEvaluate
    },

    // информация об этапах выбора (редактируются в настройках админки)
    getSteps: (state) => {
      const steps = state.verifyingParams.surveyAttributes?.steps
        ? state.verifyingParams.surveyAttributes?.steps
        : {
          evaluating: true,
          evaluated: true,
        }

      return {
        evaluating: steps?.evaluating && state?.verifyingParams?.respondentCanBeEvaluated,
        evaluated: steps?.evaluated && state?.verifyingParams?.respondentCanEvaluate,
      }
    },

    // информация об этапах верификации (редактируются в настройках админки)
    getStepsVerify: (state) => {
      if (!state.verifyingParams.surveyAttributes?.stepsVerify) {
        return {
          evaluating: true,
          evaluated: true,
        }
      }

      return state.verifyingParams.surveyAttributes?.stepsVerify
    },

    // поисковый запрос
    getSearchQuery: (state) => {
      return state.searchQuery
    },

    getSearchRespondents: (state) => {
      return state.searchRespondents
    },

    getSearchLoading: (state) => {
      return state.searchLoading
    },

    getSearchDepartmentIds: (state) => {
      const departmentIds = {}
      state.searchRespondents.forEach(respondent => {
        if (respondent?.departmentIds) {
          respondent.departmentIds.forEach(departmentId => departmentIds[departmentId] = true)
        }
      })

      return departmentIds
    },

    getRespondentsForVerification: (state) => {
      return state.respondentsForVerification
    },

    getRespondentsVerified: (_, getters) => {
      return getters.getRespondentsForVerification.filter(({ verified }) => !!verified)
    },

    getRespondentsNotVerified: (_, getters) => {
      return getters.getRespondentsForVerification.filter(({ verified }) => !verified)
    },

    getSelectedRespondentForVerification: (state) => {
      return {
        ...state.selectedRespondentForVerification,
        verify: true,
      }
    },

    getDefaultRole: (_, getters) => {
      const defaultRole = getters.getAllRoles.filter(item => item.localizedAttributes?.defaultRole)
      return defaultRole.length ? defaultRole[0].localizedAttributes?.defaultRole : null
    },

    getEvaluatingErrors: (state) => {
      return state.evaluatingErrors
    },

    getEvaluatedErrors: (state) => {
      return state.evaluatedErrors
    },
  },

  mutations: {
    setGetChoosingVerifyingParams({ verifyingParams }, data) {
      Object.keys(data).forEach(key => verifyingParams[key] = data[key])
      verifyingParams.attributesReady = true
    },

    setGetChildrenDepartmentsAndRespondents(state, { departmentId, data }) {
      const mutatedData = {
        respondents: data.respondents,
        childrenDepartments: data.childrenDepartments.map(department => ({ ...department, opened: false }))
      }
      state.childrenDepsAndResps[departmentId] = mutatedData
    },

    setClearChildrenDepartmentsAndRespondents(state) {
      state.childrenDepsAndResps = {}
    },

    setBaseModalVisible(state, bool) {
      state.isBaseModalOpen = bool
    },

    setToggleDepartment(state, { rootDepartmentId, targetDepartmentId, bool = null }) {
      const targetDepartment = state.childrenDepsAndResps[rootDepartmentId].childrenDepartments.find(({ departmentId }) => departmentId === targetDepartmentId)
      if (targetDepartment) {
        let targetBool = bool
        if (bool === null || bool === undefined) targetDepartment.opened = !targetDepartment.opened
        else targetDepartment.opened = targetBool
      }
    },

    setGetChoosingVerifyingRelations(state, data) {
      state.evaluatedRespondents = data.evaluatedRespondents
      state.evaluatingRespondents = data.evaluatingRespondents
    },

    setToggleCheckedRespondent(state, { respondentId, checked, mode = 'Evaluating', defaultRoleId }) {
      const modeLowerCase = mode.toLowerCase()

      if (checked) {
        state[`${modeLowerCase}Respondents`].push({
          respondentId,
          evaluatedRoleId: defaultRoleId,
          evaluatingRoleId: defaultRoleId,
        })
      } else {
        const targetIndex = state[`${modeLowerCase}Respondents`].findIndex(respondent => respondent.respondentId === respondentId)
        if (targetIndex > -1) {
          state[`${modeLowerCase}Respondents`].splice(targetIndex, 1)
        }
      }
    },

    setChangeChoosingCompleted(state, bool) {
      state.verifyingParams.chooseCompleted = bool
    },

    setSearchQuery(state, value) {
      state.searchQuery = value
    },

    setSearchRespondents(state, data) {
      state.searchRespondents = data
    },

    setSearchLoading(state, bool) {
      state.searchLoading = bool
    },

    setGetRespondentsForVerification(state, data) {
      state.respondentsForVerification = Object.keys(data).map((key, index) => ({
        fullName: data[key].fullName,
        email: data[key].email,
        verified: data[key].verified,
        respondentId: key,
        photoUrl: data[key].photoUrl,
        index: index + 1,
        canBeEvaluated: data[key].canBeEvaluated,
        canEvaluate: data[key].canEvaluate
      }))
    },

    setSelectedRespondentForVerification(state, data) {
      state.selectedRespondentForVerification = data
    },

    // реактивная смена фото респондента
    setChangePhotoCurrentResp(state, { respondentId, photo }) {
      Object.keys(state.childrenDepsAndResps).forEach(dep => {
        if (state.childrenDepsAndResps[dep].respondents[respondentId]) {
          state.childrenDepsAndResps[dep].respondents[respondentId].photoUrl = photo
        }
      })

      state.verifyingParams.photoUrl = photo

      const verifiedRespondent = state.respondentsForVerification.find((respondent) => respondent.respondentId === respondentId)
      if (verifiedRespondent) verifiedRespondent.photoUrl = photo
    },

    setEvaluatingErrors(state, errorsData) {
      state.evaluatingErrors = errorsData
    },

    setEvaluatedErrors(state, errorsData) {
      state.evaluatedErrors = errorsData
    }
  },

  actions: {
    async fetchGetChoosingVerifyingParams ({ commit }) {
      const { data } = await HttpService.post('/survey/feedbackx/get-choosing-verifying-params')
      commit('setGetChoosingVerifyingParams', data)
      return data
    },

    async fetchGetRespondentsForVerification ({ commit }) {
      const { data } = await HttpService.post('/survey/feedbackx/get-respondents-for-verification')
      commit('setGetRespondentsForVerification', data)
      return data
    },

    async fetchGetChoosingVerifyingRelations ({ commit }, respondentId) {
      const { data } = await HttpService.post('/survey/feedbackx/get-choosing-verifying-relations', { respondentId })
      commit('setGetChoosingVerifyingRelations', data)

      return data
    },

    async fetchGetChildrenDepartmentsAndRespondents ({ commit }, departmentId) {
      const { data } = await HttpService.post('/survey/feedbackx/get-children-departments-and-respondents', { departmentId })
      commit('setGetChildrenDepartmentsAndRespondents', { departmentId, data })

      return data
    },

    fetchAddRelation ({}, array) {
      // TODO: придумать более изящное решение
      // [0] - id респондента (меня)
      // [1] - id моей роли
      // [2] - id респондента в селекте
      // [3] - id его роли
      // [4] - режим: Evaluating | Evaluated
      // [5] - верификация: true | false
      const mode = array[4] ?? 'Evaluating'
      const isVerify = array[5] ?? false

      const payload = mode === 'Evaluating'
        ? {
          fromRespondentId:  array[2],
          fromRoleId: array[3],

          toRespondentId: array[0],
          toRoleId: array[1],
        }
        : {
          fromRespondentId:  array[0],
          fromRoleId: array[1],

          toRespondentId: array[2],
          toRoleId: array[3],
        }

      const { data } = isVerify
        ? HttpService.post('/feedbackx/verifier/add-respondents-relation', payload)
        : HttpService.post('/feedbackx/respondent/add-respondents-relation', payload)
      return data
    },

    fetchDropRelation ({}, array) {
      // TODO: придумать более изящное решение
      // [0] - id респондента (меня)
      // [1] - id кого хочу оценить
      // [2] - режим: Evaluating | Evaluated
      // [3] - верификация: true | false

      const mode = array[2] ?? 'Evaluating'
      const isVerify = array[3] ?? false

      const payload = mode === 'Evaluating'
        ? {
          fromRespondentId: array[1],
          toRespondentId: array[0],
        }
        : {
          fromRespondentId: array[0],
          toRespondentId: array[1],
        }

      const { data } = isVerify
        ? HttpService.post('/feedbackx/verifier/drop-respondents-relation', payload)
        : HttpService.post('/feedbackx/respondent/drop-respondents-relation', payload)
      return data
    },

    async fetchSearchRespondentsForChoosing ({ commit }, searchText) {
      if (searchText.length > 2) {
        const { data } = await HttpService.post('/survey/feedbackx/search-respondents-for-choosing', { searchText })
        commit('setSearchRespondents', data)
      } else {
        commit('setSearchRespondents', [])
      }
    },

    fetchSetChoosingCompleted ({ commit }, completed) {
      HttpService.post('/survey/feedbackx/set-choosing-completed', { completed })
      commit('setChangeChoosingCompleted', completed)
    },

    fetchSetVerifyingCompleted ({}, { respondentId, completed = true }) {
      HttpService.post('/survey/feedbackx/set-verifying-completed', { respondentId, completed })
    },

    fetchUploadRespondentPhoto ({}, { photo }) {
      const formData = new FormData()
      formData.append('photo', photo)

      HttpService.post('/survey/feedbackx/upload-respondent-photo', formData)
    },
  }
}
