<template>
  <div 
    class="
      text-gray min-h-[48px] mb-[20px] text-14
      md:sticky md:inset-x-0 md:z-50 md:bg-[#f2f3f7]
      md:-mx-[20px] md:my-0 md:px-[20px] md:py-[12px]
    "
    :class="{
      'md:shadow-[0px_-4px_10px_rgba(205,205,205,1)]': props.step === 2
    }"
  >
    <div v-if="props.step === 2" class="circle min-md:!hidden md:text-center overflow-hidden transition-all text-lightgray">
      <i 
        class="pi pi-arrow-circle-up" 
        style="font-size: 22px; display: block;"
      ></i>
    </div>

    <div v-html="props.title" />
  </div>
</template>

<script setup>
const props = defineProps({
  title: String,
  pseudoTitleBg: {
    type: Boolean,
    default: false,
  },
  step: {
    type: Number,
    default: 1,
  },
})
</script>

<style scoped lang="scss">
  .step-2 {
    &.step-active {
      bottom: auto;

      .circle {
        transform: rotate(180deg);
      }
    }
  }
</style>
