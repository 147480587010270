<template>
<div 
  class="absolute z-50 top-[40px] md:!hidden"
  :class="{
    '!hidden': !opened,
  }"
  :style="{
    'left': `${panelSuccessLeft}%`,
  }"
> 
  <Icon name="arrow" class="absolute -top-[30px] -right-[70px] w-[100px]" :style="{ 'color': panelSuccessBg }" />
  <Icon name="times" class="w-[14px] h-[14px]" :style="{ 'color': panelSuccessText }" />
  <div 
    class="relative desktop-panel text-14 rounded-[12px] px-[15px] py-[10px]"
    :style="{
      'background-color': panelSuccessBg,
      'color': panelSuccessText,
    }"
    v-html="getSurveyText('textSuccessPanel')"
  />
</div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useGetters } from '@/store/useStore'

import Icon from '@/components/UI/Icon.vue'

const {
  getSurveyText,
  getSurveySetting,
} = useGetters()

const opened = ref(true)

const panelSuccessLeft = computed(() => getSurveySetting.value('panelSuccessLeft') ?? 20)
const panelSuccessBg = computed(() => getSurveySetting.value('panelSuccessBg') ?? '#919fa0')
const panelSuccessText = computed(() => getSurveySetting.value('panelSuccessText') ?? '#ffffff')
</script>

<style scoped lang="scss">
  .text-success-panel::v-deep {
    span {
      font-weight: bold;
      border-bottom: 1px solid;
    }
  }

  .desktop-panel::v-deep {
    .times {
      font-family: 'primeicons';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      &:before {
        content: "\e90b";
        --webkit-backface-visibility: hidden;
        backface-visibility: hidden;

        border-radius: 50%;
        background-color: v-bind(panelSuccessText);
        color: v-bind(panelSuccessBg);
        font-weight: bold;
        font-size: 12px;
        width: 17px;
        line-height: 16px;
        display: inline-block;
        text-align: center;
        padding: 1px 0 0 0;
      }
    }
  }
</style>
