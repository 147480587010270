<template>
  <div>
    <router-view />
  </div>
</template>

<!-- TODO: все что ниже - вынести в use -->
<script setup>
import { onMounted, computed } from 'vue'
import { useActions, useGetters } from './store/useStore'
import { usePhasesController } from '@/use/useHelpers'

import '@/assets/styles/core.scss'
import 'primeicons/primeicons.css'

const {
  getSurveySetting,
} = useGetters()

const {
  fetchGetChoosingVerifyingParams,
} = useActions()

const loadCustomCss = () => {
  const customCss = getSurveySetting.value('css')
  const style = document.createElement('style')
  style.innerHTML = customCss
  document.head.appendChild(style)
}

onMounted(async () => {
  await fetchGetChoosingVerifyingParams()
  loadCustomCss()
})

usePhasesController()

const mainColor = computed(() => getSurveySetting.value('mainColor') ?? '#313131')
const mainColorContrast = computed(() => getSurveySetting.value('mainColorContrast') ?? '#ffffff')

const mainColor2 = computed(() => getSurveySetting.value('mainColor2') ?? '#313131')
const mainColorContrast2 = computed(() => getSurveySetting.value('mainColorContrast2') ?? '#ffffff')
</script>

<style lang="scss">
  .bg-color-1 {
    background-color: v-bind(mainColor);
    color: v-bind(mainColorContrast);
  }

  .color-1 {
    color: v-bind(mainColor);
  }

  .bg-color-2 {
    background-color: v-bind(mainColor2);
    color: v-bind(mainColorContrast2);
  }

  .color-2 {
    color: v-bind(mainColor2);
  }
</style>
