<template>
    <div style="position: fixed; top: 20px; right: 20px; border: 1px solid #ccc; padding: 10px; opacity: 0;">
      <button @click="start">Обнулить завершение выбора</button>
    </div>
  </template>
  
<script setup>
import { useActions } from '@/store/useStore';
import { useRouter } from 'vue-router';

const router = useRouter()

const {
    fetchSetChoosingCompleted,
} = useActions()

const start = async () => {
    await fetchSetChoosingCompleted(false)
    router.push({
        name: 'start'
    })
}

</script>

