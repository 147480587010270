<template>
<div class="p-[20px] md:pt-0">
  <div class="search relative">
    <input
      v-model="searchQuery"
      type="text"
      name=""
      placeholder="Поиск"
      class="w-full h-[40px] text-14 outline-none pl-[36px] pr-[90px] rounded-md border border-[#c6c6c6]"
      @keyup="doSearch"
    />
    <Icon name="search" class="absolute top-1/2 -translate-y-1/2 left-[12px] text-darkgray" />

    <div class="search__btn search__icon" />
    <!-- <div
      class="search__btn search__down"
      :class="{ disabled: !searchedText.length }"
    />
    <div
      class="search__btn search__up"
      :class="{ disabled: !searchedText.length }"
    /> -->
    <div
      class="search__btn search__clear"
      :class="{ disabled: !getSearchQuery.length }"
      @click="clearSearch"
    />
  </div>
</div>
</template>

<script setup>
import { ref, watch } from 'vue'

import { useActions, useGetters, useMutations } from '@/store/useStore'

import Icon from '@/components/UI/Icon.vue'

const {
  getSearchQuery,
} = useGetters()

const {
  setSearchQuery,
  setSearchLoading,
} = useMutations()

const {
  fetchSearchRespondentsForChoosing,
} = useActions()

const searchQuery = ref('')

const searchTimeout = ref(null)

const clearSearch = async () => {
  searchQuery.value = ''
  await fetchSearchRespondentsForChoosing(searchQuery.value)
}

const doSearch = () => {
  if (searchQuery.value.length > 2) setSearchLoading(true)

  clearTimeout(searchTimeout.value)
  
  searchTimeout.value = setTimeout(async () => {
    await fetchSearchRespondentsForChoosing(searchQuery.value)
    setSearchLoading(false)
  }, 500)
}

watch(() => searchQuery.value, () => {
  setSearchQuery(searchQuery.value)
}, { immediate: true })
</script>
