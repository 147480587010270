<template>
<Layout>
  <template #body>
    <EvalTemplate 
      v-if="currentRespondentId"
      mode="Evaluating" 
      :next-route="nextRoute" 
      :current-respondent-id="currentRespondentId" 
    />
  </template>
  <template #header>
    <PageHeader />
  </template>
</Layout>
</template>

<script setup>
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useGetters } from '@/store/useStore'
import { useVerify } from '@/use/useHelpers'

import Layout from '@/components/layout/Layout.vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import EvalTemplate from '@/components/EvalTemplate.vue'

const route = useRoute()

const {
  getSteps,
  getCurrentRespondent,
} = useGetters()

const isPageVerify = useVerify()

const currentRespondentId = computed(() => isPageVerify ? route.params?.respondentId : getCurrentRespondent.value.respondentId)

const nextRoute = computed(() => {
  if (isPageVerify) return 'verify'
  return getSteps.value.evaluated ? 'evaluated' : 'check'
})
</script>