<template>
<Layout>
  <template #body>
    <div class="container py-[40px] h-full">
      The page you were looking for doesn't exist (404 error)
    </div>
  </template>
</Layout>
</template>

<script setup>
import Layout from '@/components/layout/Layout.vue'
import {useGetters} from "@/store/useStore";

const { getSurveyText } = useGetters()
</script>
