<template>
  <div v-show="showRespondent && canEval" class="grid grid-cols-[max-content_auto] gap-[10px] cursor-pointer py-[10px]">
    <div>
      <Checkbox v-if="allowChange" v-model="checked" :input-id="props.respondent.respondentId" binary @change="onToggleRespondent" />
      <Icon v-else name="lock" class="w-[22px]" :style="{ 'color': getColor(props.mode) }" />
    </div>
    <label :for="props.respondent.respondentId">
      <div class="text-15 mb-[2px]">
        <Icon v-if="props.respondent?.manager ?? false" name="star" class="inline w-[16px] mr-[5px] mb-[3px]" />
        <div v-html="respondentInfo.fullName" class="inline" />
      </div>
      <div
        v-html="respondentInfo.email" 
        class="text-13 text-gray"
      />
    </label>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useActions, useGetters, useMutations } from '@/store/useStore'
import { useVerify, useFindCoupleRole } from '@/use/useHelpers'

import Checkbox from 'primevue/checkbox'
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  respondent: {
    required: true, 
  },
  mode: {
    type: String,
    required: true,
  },
})

const {
  getCurrentRespondent,
  getSearchQuery,
  getColor,
  getColorContrast,
  getDefaultRole,
  getRelations,
  getRoleById
} = useGetters()

const getRespondents = useGetters()[`getRespondents${props.mode}`]

const {
  setToggleCheckedRespondent,
} = useMutations()

const {
  fetchDropRelation,
  fetchAddRelation
} = useActions()

const isPageVerify = useVerify()

const checked = ref(
  Boolean(
    getRespondents.value.findIndex(({ respondentId }) => respondentId === props.respondent.respondentId) > -1
  )
)

const showRespondent = computed(() => {
  if (getSearchQuery.value.length > 2) {
    const fullName = props.respondent.fullName ? props.respondent.fullName.toLowerCase() : ''
    const email = props.respondent.email ? props.respondent.email.toLowerCase() : ''
    const searchQuery = getSearchQuery.value.toLowerCase()

    if (fullName.indexOf(searchQuery) > -1 || email.indexOf(searchQuery) > -1) return true
    else return false
  }
  
  return true
})

const respondentInfo = computed(() => {
  if (showRespondent.value && getSearchQuery.value.length > 2) {
    const fullName = props.respondent.fullName ? props.respondent.fullName.toLowerCase() : ''
    const email = props.respondent.email ? props.respondent.email.toLowerCase() : ''
    const searchQuery = getSearchQuery.value.toLowerCase()

    const indexFullName = fullName.indexOf(searchQuery)
    const indexEmail = email.indexOf(searchQuery)

    let markedFullName = String(props.respondent?.fullName ?? '')
    let markedEmail = String(props.respondent?.email ?? '')

    if (indexFullName >= 0) { 
      markedFullName = markedFullName.substring(0, indexFullName) + '<span class="mark">' + markedFullName.substring(indexFullName, indexFullName + searchQuery.length) + '</span>' + markedFullName.substring(indexFullName + searchQuery.length)
    }

    if (indexEmail >= 0) { 
      markedEmail = markedEmail.substring(0, indexEmail) + '<span class="mark">' + markedEmail.substring(indexEmail, indexEmail + searchQuery.length) + '</span>' + markedEmail.substring(indexEmail + searchQuery.length)
    }

    return {
      fullName: markedFullName,
      email: markedEmail,
    }
  }

  return {
    fullName: props.respondent.fullName,
    email: props.respondent.email,
  }
})

const canEval = computed(() => {
  return props.mode === 'Evaluating'
    ? props.respondent.canEvaluate
    : props.respondent.canBeEvaluated
})

const allowChange = computed(() => {
  const targetRespondent = getRespondents.value.find(respondent => respondent.respondentId === props.respondent.respondentId)
  if (targetRespondent) return targetRespondent?.allowChange ?? true
  return true
})

const color = computed(() => getColor.value(props.mode))
const colorContrast = computed(() => getColorContrast.value(props.mode))

const onToggleRespondent = () => {
  // TODO: Возможно, setToggleCheckedRespondent и fetchDropRelation надо объединить
  setToggleCheckedRespondent({
    respondentId: props.respondent.respondentId,
    checked: checked.value,
    mode: props.mode,
    defaultRoleId: (props.respondent.respondentId === getCurrentRespondent.value.respondentId) ? null : getDefaultRole.value
  })

  const currentRole = getRoleById.value(getDefaultRole.value)

  if (
    getRespondents.value.findIndex(({ respondentId }) => respondentId === props.respondent.respondentId) <= -1 &&
    !checked.value
  ) {
    fetchDropRelation([
      getCurrentRespondent.value.respondentId,
      props.respondent.respondentId,

      props.mode,
      isPageVerify,
    ])
  } else if (getDefaultRole.value) {
    fetchAddRelation([
      getCurrentRespondent.value.respondentId,
      useFindCoupleRole(...currentRole, getRelations.value),
      
      props.respondent.respondentId,
      getDefaultRole.value,
      
      props.mode,
      isPageVerify,
    ])
  }
}

watch(() => getRespondents.value, () => {
  if (
    getRespondents.value.findIndex(({ respondentId }) => respondentId === props.respondent.respondentId) <= -1
  ) {
    checked.value = false
  }
}, { deep: true })
</script>

<style scoped lang="scss">
  ::v-deep {
    .mark {
      background-color: yellow;
    }
  
    .p-checkbox .p-checkbox-box.p-highlight,
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
      border-color: v-bind(color);
      background: v-bind(color);
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      border-color: v-bind(color);
    }

    .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
      box-shadow: none !important;
    }

    .p-checkbox .p-checkbox-box .p-checkbox-icon {
      color: v-bind(colorContrast);
    }
  }
</style>