<template>
  <div 
    class="
      grid h-[100vh]
      md:h-auto md:min-h-[100vh]
    "
    :class="gridClass"
  >
    <div 
      v-if="props.twoHeaders"
      class="sticky z-50 top-0 left-0 right-0"
    >
      <slot name="header-top" />
    </div>
    <div class="relative min-md:overflow-x-auto">
      <slot name="body" />
      <div 
        class="layout-bg absolute inset-0 bg-[#ffffff] transition-all" 
        style="visibility: hidden; opacity: 0;"
      />
    </div>
    <div>
      <slot name="header" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  twoHeaders: {
    type: Boolean,
    default: false,
  },
})

const gridClass = computed(() => {
  return props.twoHeaders
    ? 'grid-rows-[max-content_auto_max-content]'
    : 'grid-rows-[auto_max-content]'
})
</script>
