<template>
  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 66.47 67.28"><defs><clipPath id="clip-path" transform="translate(-0.42 0.12)"><circle class="cls-1" cx="33.66" cy="33.66" r="33.66"/></clipPath><clipPath id="clip-path-2" transform="translate(-0.42 0.12)"><path class="cls-1" d="M56.48-15l-69.23,67.4-2.44-71.56Z"/></clipPath><radialGradient id="Безымянный_градиент" cx="4.76" cy="119.48" r="124.57" gradientTransform="matrix(1, 0, 0, -1, -0.42, 69.12)" gradientUnits="userSpaceOnUse"><stop offset="0" stop-color="#3a4a92"/><stop offset="0.02" stop-color="#3f4f95"/><stop offset="0.29" stop-color="#838dba"/><stop offset="0.53" stop-color="#b8bed8"/><stop offset="0.73" stop-color="#dfe1ed"/><stop offset="0.9" stop-color="#f6f7fa"/><stop offset="1" stop-color="#fff"/></radialGradient></defs><g id="Слой_2" data-name="Слой 2"><g id="Слой_1-2" data-name="Слой 1"><g id="Слой_2-2" data-name="Слой 2"><g id="Слой_2-2-2" data-name="Слой 2-2"><g class="cls-2"><circle class="cls-3" cx="33.24" cy="34.05" r="33.23"/><circle class="cls-4" cx="33.23" cy="33.23" r="33.23"/><path class="cls-5" d="M33.66,32.11a8,8,0,1,0-8-8,8,8,0,0,0,8,8Zm3.18,3H30.49A10.87,10.87,0,0,0,19.63,46a2.17,2.17,0,0,0,2.17,2.17H45.53A2.17,2.17,0,0,0,47.7,46h0A10.87,10.87,0,0,0,36.84,35.11Z" transform="translate(-0.42 0.12)"/><g class="cls-6"><g class="cls-7"><circle class="cls-8" cx="33.23" cy="33.23" r="31.05"/></g></g></g></g></g></g></g></svg>
</template>

<style scoped>
.cls-1 {
  fill: none;
}
.cls-2 {
  clip-path: url(#clip-path);
}
.cls-3,
.cls-5 {
  fill: #4f575c;
}
.cls-3 {
  opacity: 0.27;
}
.cls-4 {
  fill: #f3f8ff;
}
.cls-5 {
  opacity: 0.61;
}
.cls-6 {
  opacity: 0.59;
}
.cls-7{
  clip-path: url(#clip-path-2);
}
.cls-8 {
  opacity: 0.34;
  fill: url(#Безымянный_градиент);
}
</style>
