<template>
  <FileUpload 
    class="custom-upload"
    accept=".jpg,.jpeg,.png,.svg"
    auto
    custom-upload
    :multiple="false"
    mode="advanced" 
    :show-upload-button="false"
    :show-cancel-button="false"
    @uploader="onUpload($event)"
  >
    <template #header="{ chooseCallback }">
      <div 
        v-if="photo || uploadedPhoto" 
        class="absolute inset-0 rounded-full bg-[#efeff1] shadow-[0_0_5px_rgba(205,205,205,1)] bg-[#ffffff] cursor-pointer overflow-hidden"
        :class="{
          'border-2 border-[red]': errors.maxFileSize
        }"
      >
        <img 
          class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 max-w-full h-auto" 
          :src="photo?.objectURL ?? uploadedPhoto" 
        />
        <div class="absolute inset-0 cursor-pointer bg-[#ffffff80] transition-all opacity-0 hover:opacity-100" @click="chooseCallback()">
          <Icon class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[24px] text-darkgray" name="edit" />
        </div>
      </div>

      <div 
        v-else
        class="absolute inset-0 rounded-full bg-[#efeff1] shadow-[0_0_5px_rgba(205,205,205,1)] cursor-pointer transition-all overflow-hidden hover:opacity-70" 
        :class="{
          'border-2 border-[red]': errors.maxFileSize
        }"
        @click="chooseCallback()"
      > 
        <div class="absolute inset-0 before:absolute before:bg-[#efeff1] before:inset-0 before:opacity-80">
          <IconEmptyAvatar />
        </div>
        <div class="absolute top-1/2 inset-x-0 -translate-y-1/2 w-full h-f text-gray text-14 text-center">
          <Icon name="plus" class="w-[16px] inline-block mb-[4px]" />
          <div>{{ getSurveyText('textPhotoUpload') }}</div>
        </div>
      </div>
    </template>
  </FileUpload>
  <div 
    v-if="errors.maxFileSize"
    class="text-12 text-[red] opacity-70 mt-[10px] -mx-[30px] -mb-[10px]"
  >
    Максимальный размер файла не должен превышать 5MB.
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'
import { useActions, useGetters, useMutations } from '@/store/useStore'

import FileUpload from 'primevue/fileupload'
import IconEmptyAvatar from '@/components/UI/IconEmptyAvatar.vue'
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  photoUrl: String | null,
})

const maxSizeLimit = 5000000

const errors = ref({
  maxFileSize: false,
})

const {
  fetchUploadRespondentPhoto,
} = useActions()

const {
  getCurrentRespondent,
  getSurveyText,
} = useGetters()

const {
  setChangePhotoCurrentResp,
} = useMutations()

const photo = ref(null)
const uploadedPhoto = ref(null)

const onUpload = ($event) => {
  if ($event.files[0].size <= maxSizeLimit) {
    errors.value.maxFileSize = false

    photo.value = $event.files[0]
    fetchUploadRespondentPhoto({ photo: photo.value })
    setChangePhotoCurrentResp({
      respondentId: getCurrentRespondent.value.respondentId,
      photo: photo.value.objectURL,
    })
  } else {
    errors.value.maxFileSize = true
  }
}

onMounted(() => {
  uploadedPhoto.value = props.photoUrl
})
</script>

<style lang="scss" scoped>
  ::v-deep {
    .p-fileupload-buttonbar {
      position: relative;
      display: block;
      width: 100%;
      height: 0;
      background: transparent;
      border: 0;
      border-radius: 0 !important;
      padding: 0;
      padding-top: 100%;
    }
    .p-fileupload-content {
      display: none !important;
    }
  }
</style>
