<template>
<Layout class="overflow-hidden md:overflow-x-auto">
  <template #body>
    <PanelSuccessDesktop />
    <div class="relative container py-[40px] h-full md:h-auto">
      <CheckTemplate
        :current-respondent="getCurrentRespondent"
        :respondents-evaluating="getRespondentsEvaluating"
        :respondents-evaluated="getRespondentsEvaluated"
        :steps="getSteps"
      />
    </div>

    <ModalSuccess />
    <Debug class="md:hidden" />
  </template>
  <template #header>
    <PageHeader show-panel-success />
  </template>
</Layout>
</template>
  
<script setup>
import { useGetters } from '@/store/useStore'

import Layout from '@/components/layout/Layout.vue'
import PageHeader from '@/components/layout/PageHeader.vue'
import CheckTemplate from '@/components/CheckTemplate.vue'
import Debug from '@/components/Debug.vue'
import ModalSuccess from '@/components/ModalSuccess.vue'
import PanelSuccessDesktop from '@/components/panel-success/PanelSuccessDesktop.vue'

const {
  getCurrentRespondent,
  getRespondentsEvaluating,
  getRespondentsEvaluated,
  getSteps,
} = useGetters()
</script>
