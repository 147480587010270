<template>
  <div class="relative">
    <div 
      v-if="isLoading"
      class="bg-[#ffffff] px-[20px] py-[20px] mb-[10px]" 
    >
      <Skeleton width="100%" height="15px" class="mb-[6px]" />
      <Skeleton width="75%" height="15px" />

      <div class="pl-[30px] mt-[20px]">
        <Skeleton width="100%" height="10px" class="mb-[6px]" />
        <Skeleton width="75%" height="10px" class="mb-[20px]" />

        <Skeleton width="100%" height="10px" class="mb-[6px]" style="height: 10px;" />
        <Skeleton width="75%" height="10px" style="height: 10px;" />
      </div>
    </div>

    <div 
      v-if="isNothingFound" 
      v-html="getSurveyText('textIfRespondentsNotFound')"
      class="text-center bg-[#ffffff] text-14 text-darkgray px-[20px] py-[20px] mb-[10px]"
    />
    
    <div
      v-for="department in childrenDepartments"
      v-show="checkDepartmentToShow(department.departmentId)"
      :key="department.departmentId"
    >
      <div
        class="grid items-center grid-cols-[20px_auto] gap-[10px] cursor-pointer text-15 py-[15px]"
        :style="`padding-left: ${props.level * 25}px;`"
        @click="toggleDepartment(department.departmentId)"
      >
        <Icon 
          name="chevron-right"
          class="w-full transition-all"
          :class="{ 'rotate-90': department.opened }"
        />
        <span>{{ department.title }}</span>
      </div>
      
      <div v-if="department.opened">
        <div 
          class="bg-[#ffffff]" 
          :style="`padding-left: ${(props.level + 1) * 25}px;`"
        > 
          <div 
            v-if="getRespondents(department.departmentId).length" 
            class="pr-[32px]"
          > 
            <Respondent
              v-for="respondent in getRespondents(department.departmentId).filter(({ manager }) => Boolean(manager))"
              :key="respondent.respondentId"
              :respondent="respondent"
              :mode="mode"
            />

            <Respondent
              v-for="respondent in getRespondents(department.departmentId).filter(({ manager }) => !Boolean(manager))"
              :key="respondent.respondentId"
              :respondent="respondent"
              :mode="mode"
            />
          </div>
        </div>

        <DepartmentTree
          :department-id="department.departmentId"
          :level="props.level + 1"
          :mode="mode"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'

import { useActions, useGetters, useMutations } from '@/store/useStore'

import Skeleton from 'primevue/skeleton'
import Icon from '@/components/UI/Icon.vue'
import DepartmentTree from '@/components/DepartmentTree.vue'
import Respondent from '@/components/Respondent.vue'

const props = defineProps({
  departmentId: {
    default: null,
    type: String,
  },
  level: {
    default: 1,
    type: Number,
  },
  mode: {
    type: String,
    required: true,
  },
})

const {
  getChildrenDepsAndResps,
  getSearchQuery,
  getSearchDepartmentIds,
  getSearchLoading,
  getSearchRespondents,
  getSurveyText,
} = useGetters()

const getDepartmentIds = useGetters()[`getDepartmentIds${props.mode}`]

const {
  setToggleDepartment,
} = useMutations()

const {
  fetchGetChildrenDepartmentsAndRespondents,
} = useActions()

const watchingDepartments = ref(true)

const childrenDepartments = computed(() => getChildrenDepsAndResps.value(props.departmentId)?.childrenDepartments ?? [])
const childrenDepartmentsAndDepartmentIds = computed(() => {
  return {
    childrenDepartments: childrenDepartments.value,
    getDepartmentIds: getDepartmentIds.value,
  }
})

// TODO: возможно вынести в геттеры
const getRespondents = computed(() => {
  return (departmentId) => {
    const respondents = getChildrenDepsAndResps.value(departmentId)?.respondents ?? {}
    return Object.keys(respondents).map(key => ({
      respondentId: key,
      ...respondents[key],
    }))
  }
})

const isLoading = computed(() => {
  return props.level === 1 && !Object.keys(getSearchDepartmentIds.value).length && getSearchQuery.value.length > 2 && getSearchLoading.value
})

const isNothingFound = computed(() => {
  return props.level === 1 && getSearchQuery.value.length > 2 && !getSearchLoading.value && !getSearchRespondents.value.length
})

const checkDepartmentToOpen = () => {
  childrenDepartments.value.forEach(({ departmentId }) => {
    if (getDepartmentIds.value[departmentId]) {
      toggleDepartment(departmentId)
    }
  })
}

const toggleDepartment = (departmentId) => {
  setToggleDepartment({
    rootDepartmentId: props.departmentId,
    targetDepartmentId: departmentId,
  })
  watchingDepartments.value = false
}

const checkDepartmentToShow = (departmentId) => {
  if (getSearchQuery.value.length > 2) {
    const isShow = Boolean(getSearchDepartmentIds.value[departmentId])

    isShow ? setToggleDepartment({
      rootDepartmentId: props.departmentId,
      targetDepartmentId: departmentId,
      bool: Boolean(getSearchDepartmentIds.value[departmentId]),
    }) : ''

    return isShow
  }
  return true
}

onMounted(async () => {
  if (!getChildrenDepsAndResps.value(props.departmentId)) {
    await fetchGetChildrenDepartmentsAndRespondents(props.departmentId)
  }
})

watch(() => childrenDepartmentsAndDepartmentIds.value, () => {
  if (Object.keys(getDepartmentIds.value).length && watchingDepartments.value) {
    checkDepartmentToOpen()
  }
}, { immediate: true, deep: true })
</script>
