import axios from 'axios'
import deepmerge from 'deepmerge'

const getToken = () => {
  const hash = window.location.hash.split('/')
  return hash[hash.length - 1]
}

const getCurrentLocale = () => {
  const locale = window.location.hash.split('/')
  return locale[2] ?? 'ru'
}

export default {
  post (url, data = {}, extraConfig = {}) {
    const API_TOKEN = getToken()
    const currentLocale = getCurrentLocale()

    const defaultConfig = {
      headers: {
        Authorization: `Bearer ${API_TOKEN}`,
        Locale: currentLocale
      }
    }

    const config = deepmerge(defaultConfig, extraConfig)

    const requestUrl = `${process.env.VUE_APP_API}${url}`

    return axios
      .post(requestUrl, data, config)
      .catch((error) => {
        if (error.response.status === 401) {
          console.log(error.response.status)
          if (window.location.hash !== `/#/${getCurrentLocale}/401`) {
            // TODO: сделать не так колхозно
            window.location.href = `/#/${getCurrentLocale}/401`
          }
        }
        console.log(error)
      })
  }
}
