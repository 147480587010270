<template>
  <div class="relative">
    <PanelSuccessMobile v-if="showPanelSuccess" />
    <header class="relative bg-[#ffffff] shadow-[0_0_5px_rgba(205,205,205,1)] py-[14px] md:py-[10px]">
      <div class="container flex justify-between items-center">
        <div class="max-h-[40px]" :class="[isDisabledHomeBtn ? 'pointer-events-none opacity-20' : '', 'max-h-[40px]']">
          <router-link 
            :to="{
              name: pageRedirectName,
            }"
          >
            <img 
              v-if="getSurveySetting('imageLogo')"
              :src="getSurveySetting('imageLogo')" 
              class="max-w-[140px] max-h-[40px] md:max-w-[90px]" 
            />
            <Icon v-else name="home" class="w-[26px] text-darkgray" />
          </router-link>
        </div>
        <div v-if="getSurveySetting('showCopyright')" class="inline-flex items-center">
          <div class="text-14 text-[#bababa]">{{ getSurveyText('textCopyright') }}&nbsp;&nbsp;</div>
          <a href="https://happy-inc.ru/feedbackx/" target="_blank" class="block h-[26px] md:h-[20px]">
            <img :src="feedbackxSvg" class="w-auto h-full" />
          </a>
        </div>
      </div>
    </header>
  </div>
</template>

<script setup>
import { useGetters } from '@/store/useStore'
import { useVerify } from '@/use/useHelpers'

import feedbackxSvg from '@/assets/images/feedbackx.svg'
import PanelSuccessMobile from '@/components/panel-success/PanelSuccessMobile.vue'
import Icon from '@/components/UI/Icon.vue'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  showPanelSuccess: {
    type: Boolean,
    default: false,
  },
})

const route = useRoute()
const isDisabledHomeBtn = ref(false)

const { 
  getSurveyText,
  getSurveySetting,
  getPhaseChooseEnabled,
  getPhaseVerifyEnabled,
  getSelectionInProcess,
  getMinSelectedEmployeesRoles
} = useGetters()

const isSelectionRoute = route.name?.includes('evaluating') || route.name?.includes('evaluated')

if (getMinSelectedEmployeesRoles.value.length && isSelectionRoute) {
  isDisabledHomeBtn.value = true
} else {
  isDisabledHomeBtn.value = false
}

watch(getMinSelectedEmployeesRoles, (value) => {
  if (value.length === 0 || !isSelectionRoute) {
    isDisabledHomeBtn.value = false
  } else {
    isDisabledHomeBtn.value = true
  }
})

const isPageVerify = useVerify()

const pageRedirectName = computed(() => {
  return isPageVerify
    ? getPhaseVerifyEnabled.value
      ? 'startVerify' : 'verify-not-enabled'
    : getPhaseChooseEnabled.value
      ? 'start' : 'choose-not-enabled'
})
</script>
