<template>
  <div 
    class="
      grid grid-rows-[max-content_auto] h-full 
      md:block
    "
  >
    <StepSticky
      :title="getSurveyText(`textStep1${props.mode}`)"
      class="md:!hidden"
    />
    <div class="relative md:mt-[2px]">
      <div 
        class="
          grid grid-rows-[max-content_auto]
          absolute inset-0 overflow-hidden
          rounded-md shadow-[0_0_5px_rgba(205,205,205,1)]
          border border-[#dfe3e6]
          md:shadow-[0_0_0_transparent] md:border-none
          md:relative md:p-0 md:-mx-[20px]
        "
      >
        <PanelSearch/>
        <div 
          class="
            pretty-scrollbar wide h-full overflow-x-auto 
            md:h-auto
          "
        >
          <DepartmentTree :mode="props.mode" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useGetters } from '@/store/useStore'

import StepSticky from '@/components/StepSticky.vue'
import PanelSearch from '@/components/PanelSearch.vue'
import DepartmentTree from '@/components/DepartmentTree.vue'

const props = defineProps({
  mode: {
    type: String,
    required: true,
  },
})

const {
  getSurveyText,
} = useGetters()
</script>
