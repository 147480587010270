<template>
<li class="py-2">
  <div class="flex items-center space-x-4">
    <div class="w-[32px]">
      <RespondentPhoto :photo-url="respondentInfo?.photoUrl ?? null" />
    </div>
    <div class="flex-1 min-w-0">
      <p class="text-darkgray text-14">{{ respondentInfo?.fullName ?? '-.-' }}</p>
    </div>
    <Icon v-if="!respondent.allowChange" name="lock" class="w-[16px] text-[#cccccc] ml-[4px] my-[4px]" />
  </div>
</li>
</template>

<script setup>
import { computed } from 'vue'

import { useGetters } from '@/store/useStore'

import RespondentPhoto from '@/components/UI/RespondentPhoto.vue'
import Icon from '@/components/UI/Icon.vue'

const props = defineProps({
  respondent: Object,
  mode: {
    type: String,
    required: true,
  },
})

const {
  getRespondentById,
} = useGetters()

const respondentInfo = computed(() => getRespondentById.value(props.respondent.respondentId))
</script>
