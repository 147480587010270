<template>
  <div class="relative bg-[#ffffff] shadow-[0_0_5px_rgba(205,205,205,1)] py-[14px] md:py-[10px]">
    <div class="container">
      <div 
        class="
          relative grid items-center gap-[16px] grid-cols-[auto_max-content_max-content_max-content]
          md:grid-cols-[64px_auto_auto_max-content] md:gap-[8px]
        "
      >
        <div class="absolute -left-[44px] rotate-[116deg] w-[60px] mt-[12px] md:!hidden">
          <Icon name="arrow" class="w-full" />
        </div>
        
        <div 
          class="
            text-24 font-medium col-span-4 mt-[10px]
            md:leading-[1.4] md:text-16 md:col-span-3 md:order-1 md:mt-0
          "
        >
          <span>
            {{ 
              allRespondentsVerified 
                ? getSurveyText('textTitleCheckVerifiedVerify') 
                : getSurveyText('textTitleCheckVerify') 
            }}
          </span>
          <PanelSuccessDesktopVerify v-if="allRespondentsVerified" />
        </div>

        <Dropdown
          v-model="selectedRespondent"
          :options="getRespondentsForVerification"
          :scroll-height="`${getRespondentsForVerification.length >= 8 ? '400px' : '200px'}`"
          class="dropdown-verify w-full rounded-md md:order-3 md:col-span-4"
          @change="changeCheckingRespondent"
          @show="toggleLayoutBg('show')"
          @hide="toggleLayoutBg('hide')"
        >
          <template #value="slotProps">
            <div class="flex items-center justify-between px-[10px] md:px-0">
              <div class="flex items-center">
                <div class="relative w-[32px] h-[32px] mr-[10px]">
                  <RespondentPhoto :photo-url="slotProps?.value?.photoUrl ?? null" />
                  <div 
                    v-show="slotProps?.value?.verified ?? false" 
                    class="absolute right-[16px] top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-darkgray w-[18px] h-[18px] leading-[18px] text-center text-[#ffffff]"
                  >
                    <i class="pi pi-check" style="font-size: 10px; font-weight: bold;" />
                  </div>
                </div>
                <div>
                  <div class="text-12">{{ slotProps?.value?.fullName ?? '-.-' }}</div>
                  <div class="text-12 text-gray truncate opacity-60">{{ slotProps?.value?.email ?? '-.-' }}</div>
                </div>
              </div>
              <div 
                ref="dropdownLabel"
                class="text-right md:ml-[5px] md:mr-0"
                :class="{
                  'font-bold text-[#313131]': slotProps?.value?.verified,
                  'font-[400] text-[#e86161]': !slotProps?.value?.verified,
                }"
              >
                {{ 
                  slotProps?.value?.verified 
                    ? getSurveyText('textVerifiedVerify') 
                    : getSurveyText('textNotVerifiedVerify') 
                }}
              </div>
            </div>
          </template>

          <template #option="slotProps">
            <div class="flex items-center justify-between px-[11px] md:px-0">
              <div class="inline-flex align-items-center">
                <div class="flex items-center">
                  <div class="relative w-[32px] h-[32px] mr-[10px]">
                    <RespondentPhoto :photo-url="slotProps?.option?.photoUrl ?? null" />
                    <div 
                      v-show="slotProps?.option?.verified ?? false" 
                      class="absolute right-[16px] top-1/2 -translate-x-1/2 -translate-y-1/2 rounded-full bg-darkgray w-[18px] h-[18px] leading-[18px] text-center text-[#ffffff]"
                    >
                      <i class="pi pi-check" style="font-size: 10px; font-weight: bold;" />
                    </div>
                  </div>
                  <div>
                    <div class="text-12">{{ slotProps?.option?.fullName }}</div>
                    <div class="text-12 text-gray truncate opacity-60">{{ slotProps?.option?.email }}</div>
                  </div>
                </div>
              </div>
              <div 
                class="text-[#313131] mr-[34px] md:pr-0 md:mr-0"
                :class="{
                  'font-bold': slotProps?.option?.verified,
                  'font-[400] text-[#e86161]': !slotProps?.option?.verified,
                }"
              >
                {{ 
                  slotProps?.option?.verified 
                    ? getSurveyText('textVerifiedVerify') 
                    : getSurveyText('textNotVerifiedVerify') 
                }}
              </div>
            </div>
          </template>
        </Dropdown>

        <div class="md:order-4">
          <button 
            :disabled="selectedRespondent?.index === 1"
            class="bg-[#ffffff] text-darkgray w-[32px] h-[46px] border border-[#dadada] transition-all rounded-l-md disabled:opacity-70" 
            @click="toggleCheckingRespondent('prev')"
          >
            <span class="pi pi-arrow-left" />
          </button>
          <button 
            :disabled="selectedRespondent?.index === getRespondentsForVerification.length"
            class="bg-[#ffffff] text-darkgray w-[32px] h-[46px] border border-[#dadada] transition-all rounded-r-md disabled:opacity-70 -ml-[1px]" 
            @click="toggleCheckingRespondent('next')"
          >
            <span class="pi pi-arrow-right" />
          </button>
        </div>

        <button 
          class="button !px-[20px] md:order-4 md:col-span-3"
          :style="{
            'background-color': selectedRespondent?.verified ? '#cbcbcb' : '#353535',
            'color': '#ffffff'
          }"
          @click.stop="verifyingComplete"
        >
          <i 
            class="pi mr-[8px]" 
            :class="[ selectedRespondent?.verified ? 'pi-replay' : 'pi-check' ]"
            style="font-weight: bold; width: 16px;" 
          />
          <span class="relative">
            <span class="opacity-0">
              {{ 
                getSurveyText('textButtonEditCheck').length >= getSurveyText('textButtonConfirmCheck').length
                  ? getSurveyText('textButtonEditCheck')
                  : getSurveyText('textButtonConfirmCheck')
              }}
            </span>

            <span class="absolute inset-0">
              {{ 
                selectedRespondent?.verified
                  ? getSurveyText('textButtonEditCheck') 
                  : getSurveyText('textButtonConfirmCheck') 
              }}
            </span>
          </span>
        </button>

        <div 
          class="
            text-gray text-right text-14 mb-[3px]
            md:order-2
          "
        >
          <span class="md:!hidden">{{ getSurveyText('textStepCheckedTotalVerify') }}&nbsp;</span>
          <span class="text-[#ffffff] text-22 font-medium md:text-16 inline-block min-w-[33px] bg-[#535353] rounded-full text-center px-[5px]">{{ getRespondentsVerified.length }}</span>
          <span class="mx-[6px]">{{ getSurveyText('textStepCheckedMiddleVerify') }}</span>
          <span class="inline-block text-[#000000] text-22 font-medium md:text-16">{{ getRespondentsForVerification.length }}</span>
        </div>
      </div>
    </div>
  </div>
  <BaseModal>
    <template v-slot:body>
      <div class="grid gap-[10px]">
        <ul v-for="(errorsArray, arrayIndex) in errorsMessage" :key="arrayIndex">
          <li v-for="(error, errorIndex) in errorsArray" :key="errorIndex">
            <span :class="[errorIndex === 0 ? 'font-medium ': '']">{{ error }}</span>
          </li>
          <hr v-if="isNeedHr(errorsArray, arrayIndex)" class="mt-3" />
        </ul>
      </div>
    </template>
  </BaseModal>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue'
  import { useRoute, useRouter } from 'vue-router'
  
  import { useActions, useGetters, useMutations } from '@/store/useStore'
  
  import RespondentPhoto from '@/components/UI/RespondentPhoto.vue'
  import PanelSuccessDesktopVerify from '@/components/panel-success/PanelSuccessDesktopVerify.vue'
  import Icon from '@/components/UI/Icon.vue'
  import Dropdown from 'primevue/dropdown'
  import BaseModal from '@/components/UI/BaseModal.vue'

  const route = useRoute()
  const router = useRouter()
  const errorsMessage = ref([])
  
  const {
    getRespondentsForVerification,
    getRespondentsVerified,
    getSurveyText,
    getMinSelectedEmployeesRoles,
    getRoleById,
    getRelations,
    getEvaluatingErrors,
    getEvaluatedErrors
  } = useGetters()
  
  const {
    setSelectedRespondentForVerification,
    setBaseModalVisible
  } = useMutations()
  
  const {
    fetchGetRespondentsForVerification,
    fetchSetVerifyingCompleted,
  } = useActions()
  
  const selectedRespondent = ref(null)
  const dropdownLabel = ref(null)

  const isNeedHr = (errorsArray, arrayIndex) => {
    const lastErrorMessageIndex = errorsMessage.value.length - 1
    return errorsArray.length && arrayIndex !== lastErrorMessageIndex && errorsMessage.value[lastErrorMessageIndex].length
  }

  const allRespondentsVerified = computed(() => getRespondentsVerified.value.length === getRespondentsForVerification.value.length)
  
  const changeCheckingRespondent = async () => {
    setSelectedRespondentForVerification(selectedRespondent.value)
    router.push({ params: { respondentId: selectedRespondent.value.respondentId } })
  }
  
  const toggleCheckingRespondent = async (direction) => {
    const selectedIndex = selectedRespondent.value.index - 1
    const indexGap = direction === 'next' ? + 1 : -1 
    selectedRespondent.value = getRespondentsForVerification.value[selectedIndex + indexGap]
    await changeCheckingRespondent(selectedRespondent.value.respondentId)
  }
  
  const verifyingComplete = async () => {
    errorsMessage.value = []

    if (getEvaluatingErrors.value.length || getEvaluatedErrors.value.length) {
      errorsMessage.value.push(getEvaluatingErrors.value, getEvaluatedErrors.value)
      setBaseModalVisible(true)
    } else {
      fetchSetVerifyingCompleted({
        respondentId: selectedRespondent.value.respondentId,
        completed: !selectedRespondent.value.verified,
      })
    }
  
    // TODO: это можно оптимизировать
    await initData(selectedRespondent.value.index - 1)
  }
  
  const initData = async (index = null) => {
    await fetchGetRespondentsForVerification()
    
    let selectedRespondentIndex = 0
    if (route.params?.respondentId) {
      selectedRespondentIndex = getRespondentsForVerification.value.findIndex(({ respondentId }) => respondentId === route.params.respondentId) ?? 0
    }
    if (index) selectedRespondentIndex = index
  
    selectedRespondent.value = getRespondentsForVerification.value[selectedRespondentIndex]
    await changeCheckingRespondent(selectedRespondent.value.respondentId)
  }

  const toggleLayoutBg = (event) => {
    const layoutBg = document.querySelector('.layout-bg')

    if (event === 'show') {
      layoutBg.style.visibility = 'visible'
      layoutBg.style.opacity = '0.7'

      dropdownLabel.value.style.opacity = '0.5'
      dropdownLabel.value.style.color = '#313131'
      dropdownLabel.value.style.fontWeight = 'normal'
    } else {
      layoutBg.style.visibility = 'hidden'
      layoutBg.style.opacity = '0'

      dropdownLabel.value.style.opacity = null
      dropdownLabel.value.style.color = null
      dropdownLabel.value.style.fontWeight = null
    }
  }
  
  onMounted(async () => {
    await initData()
  })
  </script>
  