<template>
  <div class="relative w-full h-0 pt-[100%] rounded-full overflow-hidden">
    <img
      v-if="respondentPhoto"
      :src="respondentPhoto" 
      class="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-auto "
    />
    <IconEmptyAvatar 
      v-else 
      class="absolute w-full h-full inset-0" 
    />
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'

import IconEmptyAvatar from '@/components/UI/IconEmptyAvatar.vue'

const props = defineProps({
  photoUrl: String | null
})

const respondentPhoto = ref(null)

// TODO: порефакторить и сделать более красиво

watch(() => props.photoUrl, () => {
  if (props.photoUrl) {
    const originalUrl = props.photoUrl
    
    const lastIndex = originalUrl.lastIndexOf('.')

    const titleUrl = originalUrl.slice(0, lastIndex)
    const format = originalUrl.slice(lastIndex)

    const webpOptimizedUrl = new Image()
    const optimizedUrl = new Image()

    webpOptimizedUrl.src = `${titleUrl}.optimized.webp`
    webpOptimizedUrl.onload = () => {
      respondentPhoto.value = webpOptimizedUrl.src
    }
    webpOptimizedUrl.onerror = () => {
      optimizedUrl.src = `${titleUrl}.optimized${format}`
      optimizedUrl.onload = () => {
        respondentPhoto.value = optimizedUrl.src
      }
      optimizedUrl.onerror = () => {
        respondentPhoto.value = originalUrl
      }
    }
  } else {
    respondentPhoto.value = null
  }
}, { immediate: true })
</script>
